<template>
  <vx-card :title="title">
    <vs-tabs :value="active">
      <vs-tab label="Open">
        <Open></Open>
      </vs-tab>
      <vs-tab label="Approved">
        <Approved></Approved>
      </vs-tab>
      <vs-tab label="Rejected">
        <Rejected></Rejected>
      </vs-tab>
      <vs-tab label="Inquiry">
        <Inquiry></Inquiry>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>
<script>
import Open from "./_tab/Open.vue";
import Approved from "./_tab/ApproveHistory.vue";
import Rejected from "./_tab/RejectHistory.vue";
import Inquiry from "./_tab/InquiryHistory.vue";
export default {
  props: {
    active: {
      type: Number,
    },
  },
  components: {
    Open,
    Approved,
    Rejected,
    Inquiry,
  },
  data() {
    return {
      title: "Aprroval Credit Limit Request Payment Term",
    };
  },
};
</script>
